import {
    Box,
    Button,
    InputBase,
    styled,
    TextField,
    InputAdornment,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { post, URLs, DB } from "./API/API";
import SearchIcon from "@mui/icons-material/Search";
import {
    FullFlexCenterStyle,
    LeftBarContainerStyle,
    LeftBarStoryStyle,
    SearchBarContainerStyle,
    SearchBarTextFieldStyle,
    SearchStyle,
} from "../Style/Ai_style";

const originStories = [
    { id: "1", title: "흥부와 놀부", body: "흥부와 놀부 이야기입니다만?" },
    { id: "2", title: "스파이 패밀리", body: "스파이 패밀리 이야기 입니다만?" },
    { id: "3", title: "최애의 아이", body: "최애의 아이 이야기 입니다만?" },
    { id: "4", title: "이누야샤", body: "이누야샤 이야기입니다만?" },
];

export const Style = styled(Box)({
    border: "1px solid white",
    borderRadius: "20px",
    px: "16px",
    py: "2px",
    width: 0.6,
});

function SearchBar(props) {
    return (
        <SearchBarContainerStyle>
            <SearchStyle> 검색 </SearchStyle>
            <SearchBarTextFieldStyle>
                <InputBase
                    inputProps={{
                        style: {
                            color: "white",
                        },
                    }}
                    sx={{
                        pt: "2px",
                    }}
                    endAdornment={
                        <InputAdornment position="end">
                            <SearchIcon sx={{ color: "white" }} />
                        </InputAdornment>
                    }
                    size="small"
                    value={props.word}
                    onChange={(e) => props.setWord(e.target.value)}
                />
            </SearchBarTextFieldStyle>
        </SearchBarContainerStyle>
    );
}

export function LeftBar(props) {
    const [word, setWord] = useState("");
    return (
        <LeftBarContainerStyle>
            <SearchBar word={word} setWord={setWord} />
            {props.stories
                .filter((story) => story.title.includes(word))
                .map((one, index) => (
                    <LeftBarStoryStyle sx={{bgcolor: props.selectedStories.find(ss => ss.id === one.id ) ? "#333":"#444"}} key={index}>
                        <Box
                            sx={{ cursor: "pointer"}}
                            onClick={() => {
                                props.setStory(one)
                                const found = props.selectedStories.find(ss => ss.id === one.id )
                                if (found)
                                    props.setSelectedStories(props.selectedStories.filter(ss => ss.id !== one.id))
                                else
                                    props.setSelectedStories([...props.selectedStories, one])
                            }}
                        >
                            {one.title}
                        </Box>
                    </LeftBarStoryStyle>
                ))}
            <Box sx={{width: "calc(100% - 16px)", display:'flex', gap:1}}> 
                <Button
                    sx={{
                        backgroundColor: "red",
                        color: "white",
                    }}
                    onClick={() => {
                        post(URLs.DELETE_JSON_CONTENT, {
                            file_name: DB.STORY,
                            content: props.selectedStories.map(one => one.id)
                        }, (res)=>{
                            if (typeof res === 'object')
                                props.setStories(res)
                            console.log (res)
                        })
                        props.setSelectedStories([])
                    }}
                >
                    삭제
                </Button>
                <Button
                    sx={{
                        backgroundColor: "#333",
                        color: "white",
                    }}
                    onClick={() => props.pushStory()}
                >
                    추가
                </Button>
            </Box>
            
        </LeftBarContainerStyle>
    );
}

export const ScrollDiv = styled("div")`
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 4px;
        background: #555;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: #888;
    }
`;

export function Content(props) {

    return (
        <Box
            sx={{
                width: "calc(100% - 250px)",
                backgroundColor: "#555",
                position: "relative",
            }}
        >
            <ScrollDiv style={{ height: "100%", overflow: "auto" }}>
                <FullFlexCenterStyle>
                    <Box
                        sx={{
                            boxSizing: "border-box",
                            p: 3,
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                        }}
                    >
                        <Box>
                            <TextField
                                sx={{
                                    border: "1px solid white",
                                    borderRadius: 2,
                                }}
                                inputProps={{
                                    style: {
                                        color: "white",
                                    },
                                }}
                                fullWidth
                                value={props.story.title}
                                onChange={(e) =>
                                    props.setStory({
                                        ...props.story,
                                        title: e.target.value,
                                    })
                                }
                            />
                        </Box>
                        <Box>
                            <TextField
                                multiline
                                fullWidth
                                sx={{
                                    border: "1px solid white",
                                    borderRadius: 2,
                                }}
                                inputProps={{
                                    style: {
                                        color: "white",
                                        height: "calc(100vh - 500px)",
                                    },
                                }}
                                value={props.story.body}

                                onChange={(e) =>
                                    props.setStory({
                                        ...props.story,
                                        body: e.target.value,
                                    })
                                }
                            />
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            boxSizing: "border-box",
                            px: 3,
                            display: "flex",
                            justifyContent: "flex-end",
                            zIndex: 111,
                        }}
                    >
                        <Button
                            sx={{
                                backgroundColor: "#333",
                                color: "white",
                            }}
                            // onClick={() => {
                            //     props.saveStory(props.story.id, props.story);
                            // }}
                            onClick={() => {
                                props.saveStory();
                            }}
                        >
                            저장
                        </Button>
                    </Box>
                </FullFlexCenterStyle>
            </ScrollDiv>
        </Box>
    );
}

export default function AI(props) {
    const [stories, setStories] = useState(originStories);
    const [story, setStory] = useState(originStories[0]);
    const [selectedStories, setSelectedStories] = useState([])

    useEffect(() => {
        post(URLs.GET_JSON_FILE, DB.STORY, (res) => {
            console.log (res)
            setStories(res)
        })
    }, [])

    function pushStory() {
        post(URLs.APPEND_JSON_CONTENT, {file_name:DB.STORY, content:{
            title:'새롭게 추가된 이야기', body:'새롭게 추가된 이야기입니다.'
        }}, (res) => {
            console.log (res)
            setStories(res);
        })
    }


    function saveStory() {
        let index = stories.findIndex((one) => one.id === story.id);
        if (index !== -1) {
            const newStories = [...stories];
            newStories[index] = story;
            setStories(newStories);

            post(URLs.SAVE_JSON_FILE, {file_name:DB.STORY, content: newStories},(res) => {
                console.log (res)
            })
        }
    }

    function deleteStory(id) {
        const newStories = stories.filter((one) => one.id !== id);
        setStories(newStories);
    }

    return (
        <Box
            sx={{
                width: "100%",
                height: "calc(100vh - 282px)",
                display: "flex",
            }}
        >
            <LeftBar
                deleteStory={deleteStory}
                stories={stories}
                setStories={setStories}
                selectedStories={selectedStories} 
                setSelectedStories={setSelectedStories}
                pushStory={pushStory}
                setStory={setStory}
            />
            <Content story={story} setStory={setStory} saveStory={saveStory} />
        </Box>
    );
}
